export const cardText =[
    "Sluta helt att investera i infrastruktur som ger större utsläpp av växthusgaser",
    "Ta bort alla subventioner och skattelättnader till fossilenergianvändning",
    "Inför ett tillräckligt högt pris på kol, för alla sektorer i samhället, begränsa import av produktion baserad på kol",
    "Stöd EU's arbete med klimatomställningen",
    "Ställ om matproduktionen till stöd för regenerativt jordbruk, lokal svensk produktion och mer växtbaserad kost",
    "Säkra naturens kolsänkor, vattenflöden och myller av liv, och ge Sveriges miljömål högsta prioritet till 2030",
    "Se till att finanssektorns verksamhet hålls inom planetens gränser",
    "Inför nya principer för ekonomisk utveckling baserat på cirkulär ekonomi och mänsklig utveckling",
    "Inför en tvingande klimatlag i så många länder som möjligt",
    "Starta ett globalt jätteprojekt; välfärd och rättvisa inom planetens gränser på tio år",
]
