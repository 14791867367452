export const cardTextEng =[
    "Stop all investments in infrastructure that contributes to enhanced greenhouse gas emissions.", 
    "Remove all subsidies and tax credits for fossil energy use.",
    "Introduce a sufficiently high price on coal, for all sectors of society, together with import restrictions for coal-based production goods.",
    "Support EU actions on climate change toward societal transformation.",
    "Transform the food production system in support of regenerative agriculture, local Swedish food production and more plant-based diets.",
    "Secure nature's carbon sinks, water flows and webs of life, and give Sweden's Environmental Goals the highest priority by 2030.",
    "Ensure that the financial sector's operations are kept within the planetary boundaries." ,
    "Introduce new principles for economic development based on circular economy and human development.",
    "Introduce a cogent Climate Act, in as many countries as possible.",
    "Start a global mega project: welfare and justice within the planetary boundaries in ten years.",
]
